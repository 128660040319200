/* Inputs */
%field-style {
  padding: 12px 0;
  color: $color--gray2;
  border: 0;
  border-bottom: 1px solid $color--coal;
  background: transparent;
  width: 100%;
  display: block;
  border-radius: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  height: 40px;
  transition: .2s $cubic;
  background-color: $color--white;

  &:disabled {
    pointer-events: none;
  }

  &:focus {
    outline: 0;
  }
}

.form-label {
  color: $color--gray5;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  display: flex;
  align-items: flex-start;

  .required {
    color: $color--yellow;
  }
}

.form-2-columns {
  @include min-screen(bp("phablet")) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 32px;
  }
}

.form-row-1-2 {
  @include min-screen(bp("phablet")) {
    display: grid;
    grid-template-columns: .7fr .3fr;
    grid-gap: $unit-spacing;
  }
}

.form-group {
  width: 100%;
  margin-bottom: $unit-spacing * 2;
}

.form-input {
  @extend %field-style;
}

.form-select {
  @extend %field-style;
  -webkit-appearance: none;
}

.form-textarea {
  @extend %field-style;
  height: 144px;
  resize: none;
}

