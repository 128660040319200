/* Cards */
.card-post {
  @include backgroundCover();
  width: 100%;
  height: 450px;
  position: relative;
  color: $color--white;
  padding: 40px 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  border-radius: 5px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, $color--blue3 0%, rgba($color--blue3, 0) 100%);
    z-index: 1;
  }

  > * {
    position: relative;
    z-index: 2;
  }

  &__category {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: .2em;
    text-transform: uppercase;
    color: $color--yellow;
    margin-bottom: 20px;
  }

  &__title {
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
  }

  a {
    margin-top: auto;
  }

  &__badge {
    position: absolute;
    width: 108px;
    height: 90px;
    border-top: 54px solid transparent;
    border-right: 54px solid #ec0000;
    border-bottom: 54px solid #ec0000;
    border-left: 54px solid transparent;
    bottom: 0;
    right: 0;
    z-index: 3;
    pointer-events: none;

    i {
      font-size: 28px;
      position: absolute;
      bottom: -36px;
      right: -38px;
      color: $color--white;
    }
  }

  @include min-screen(bp("page")) {
    padding: 70px 30px 30px;
    height: 450px;
  }
}
