/* Muralha */
.concept {
  @include backgroundCover();
  background-position-y: bottom;
  padding: 100px 0;

  &__left,
  &__right {
    p + p {
      margin-top: $unit-spacing;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 35px;
    line-height: 34px;
    letter-spacing: -.005em;
    position: relative;
    z-index: 1;
    margin-bottom: $unit-spacing;

    &::before {
      content: "";
      position: absolute;
      top: -63px;
      left: -5px;
      width: 127px;
      height: 90px;
      background-size: 127px 90px;
      background-image: url("data:image/svg+xml,%3Csvg width='150' height='106' viewBox='0 0 150 106' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M85.6829 106V66.8261C85.6829 53.5121 86.3193 42.5024 87.5921 33.7971C88.865 25.0918 91.6652 18.3068 95.9929 13.442C100.575 8.32125 107.067 4.86473 115.467 3.07247C124.123 1.02416 135.578 0 149.834 0V23.0435C142.961 23.0435 137.615 23.6836 133.796 24.9638C129.978 26.244 127.432 28.6763 126.159 32.2609C124.886 35.8454 124.25 40.8382 124.25 47.2391V53.3841H147.161V106H85.6829ZM0.911621 106V66.8261C0.911621 53.5121 1.54804 42.5024 2.82088 33.7971C4.34829 25.0918 7.27583 18.3068 11.6035 13.442C15.9311 8.32125 22.4226 4.86473 31.078 3.07247C39.7333 1.02416 51.1889 0 65.4447 0V23.0435C58.5713 23.0435 53.2254 23.6836 49.4069 24.9638C45.5884 26.244 43.0427 28.6763 41.7698 32.2609C40.497 35.8454 39.8606 40.8382 39.8606 47.2391V53.3841H62.7717V106H0.911621Z' fill='%23FFB800'/%3E%3C/svg%3E");
      z-index: -1;
    }
  }

  &__right {
    margin-top: $unit-spacing * 2;
  }

  &__separator {
    display: block;
    margin: 0 0 20px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: 83px 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='83' height='16' viewBox='0 0 83 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 16V0H18.7081V16H0Z' fill='%23FFB800'/%3E%3Cpath d='M32.146 16V0H50.854V16H32.146Z' fill='%23FFB800'/%3E%3Cpath d='M64.2919 16V0H83V16H64.2919Z' fill='%23FFB800'/%3E%3C/svg%3E");
  }

  .city {
    position: absolute;
    max-width: 620px;
    right: 50%;
    margin-right: -290px;
    top: 100%;
  }
  
  @include min-screen(bp("phablet")) {
    padding-bottom: 50px;

    &__left {
      width: 50%;
      max-width: 570px;
      padding-right: $unit-spacing * 2;
    }

    &__right {
      width: 50%;
      margin-left: auto;
      margin-top: 0;
    }

    &__separator {
      margin: 60px 0 20px;
    }

    .city {
      margin-right: 50px;
      top: 30%;
    }
  }
  
  @include min-screen(bp("page")) {
    font-size: 20px;
    line-height: 28px;
    padding: 160px 0 125px;

    &__title {
      max-width: 372px;
      margin-bottom: $unit-spacing * 2;
      font-size: 40px;
      line-height: 40px;

      &::before {
        left: -29px;
        width: 150px;
        height: 106px;
        background-size: 150px 106px;
      }
    }

    .city {
      margin-right: 100px;
      top: 430px;
      max-width: 788px;
    }
  }
}

.numbers {
  position: relative;
  margin-top: 450px;
  overflow: hidden;

  &__title {
    font-size: 35px;
    font-weight: 700;
    line-height: 101.9%;
    letter-spacing: -.175px;
    max-width: 510px;
    color: $color--blue3;
    margin-bottom: $unit-spacing;
  }

  &__grid {
    margin-top: $unit-spacing * 2;
    display: flex;
    flex-wrap: wrap;
  }

  .number {
    width: 100%;
    padding: $unit-spacing * 3 $unit-spacing * 2;
    background: linear-gradient(180deg, rgba($color--gray2, .2) 70%, rgba($color--blue3, 0) 120%);
    position: relative;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 101.9%;
    letter-spacing: -.09px;
    color: $color--gray2;
    min-height: 172px;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(90deg, #fac20a 0%, rgba(250, 194, 10, 0) 100%);
    }

    small {
      font-size: 12.902px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -.065px;
      display: block;
    }

    &__title {
      font-weight: 700;
      font-size: 51px;
      line-height: 101.9%;
      letter-spacing: -.255px;
      color: $color--blue3;
      margin-bottom: $unit-spacing / 2;
    }
  }

  .data-from {
    color: $color--gray2;
    text-align: right;
    font-size: 16px;
    font-weight: 400;
    line-height: 102%;
    letter-spacing: -.08px;
    margin-top: $unit-spacing;
  }

  @include min-screen(bp("phablet")) {
    margin-top: 100px;

    .number {
      width: 50%;

      &::before {
        top: 0;
        width: 1px;
        height: 100%;
        background: linear-gradient(180deg, #fac20a 0%, rgba(250, 194, 10, 0) 100%);
      }

      &:nth-child(2n) {
        &::before {
          display: none;
        }
      }
    }
  }

  @include min-screen(bp("large")) {
    &__title {
      font-size: 40px;
      line-height: 102%;
      letter-spacing: -.2px;
      margin-bottom: $unit-spacing * 3;
    }

    &__grid {
      margin-top: $unit-spacing * 3;
    }

    .number {
      padding: 97px $unit-spacing * 3;
      background: linear-gradient(180deg, rgba($color--gray, .2) 0%, rgba($color--blue3, 0) 100%);
    }
  }
  
  @include min-screen(bp("page")) {
    margin-top: 200px;

    .number {
      min-height: 353px;
      font-size: 35px;
      line-height: 102%;
      letter-spacing: -.175px;

      &__title {
        font-size: 100px;
        line-height: 102%;
        letter-spacing: -.5px;
        margin-bottom: $unit-spacing * 2;
      }

      small {
        font-size: 25px;
        line-height: 35px;
        letter-spacing: -.15px;
      }
    }
  }
  
  @include min-screen(bp("big")) {
    margin-top: 200px;
  }
}

.cases {
  padding: $unit-spacing * 3 0 $unit-spacing * 5;
  background-color: $color--yellow;

  &__title {
    color: $color--blue3;
    font-weight: 700;
    font-size: 35px;
    line-height: 101.9%;
    letter-spacing: -.175px;
    margin-bottom: $unit-spacing * 2;
  }

  &__grid {
    display: grid;
    gap: $unit-spacing * 2;
    grid-template-columns: 1fr;
  }

  &__text {
    color: $color--blue3;
    font-size: 30px;
    font-weight: 500;
    line-height: 40px; 
    letter-spacing: -.15px;

    .button {
      margin-top: $unit-spacing * 2;
    }
  }
  
  @include min-screen(bp("phablet")) {
    &__grid {
      gap: $unit-spacing * 2;
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @include min-screen(bp("page")) {
    padding: 145px 0;

    &__title {
      font-size: 40px;
      line-height: 101.9%;
      letter-spacing: -.2px;
      margin-bottom: $unit-spacing * 3;
    }

    &__grid {
      gap: 9px;
      grid-template-columns: auto auto auto 390px;
    }

    &__text {
      padding-left: $unit-spacing * 2;
      font-size: 30px;
      line-height: 40px;
      letter-spacing: -.15px;
    }

  }
}

.quick-contact {
  @include backgroundCover();
  padding: 60px 0;
  color: $color--white;
  background-color: $color--blue;
  background-blend-mode: overlay;

  &__title {
    font-weight: 700;
    font-size: 35px;
    line-height: 36px;
    letter-spacing: -.005em;
  }

  &__form {
    width: 100%;
    margin-top: $unit-spacing;
    background-color: $color--white;
    padding: $unit-spacing * 2 $unit-spacing;
  }

  @include min-screen(bp("large")) {
    padding: 100px 0;

    &__title {
      font-size: 40px;
      line-height: 41px;
    }

    &__form {
      width: 54%;
      margin-left: auto;
      margin-top: 0;
      padding: $unit-spacing * 3;
    }
  }

  @include min-screen(bp("wide")) {
    padding: 210px 0;

    &__title {
      font-size: 50px;
      line-height: 51px;
    }
  }
}
