/* variables */

/* font */

/* typo */

/* colors */

/* misc */

/* Mixins */

/* Fonts */

@font-face {
  font-family: "font-icon";
  src: url("../fonts/font-icon.eot");
  src: url("../fonts/font-icon.eot#iefix") format("embedded-opentype"), url("../fonts/font-icon.ttf") format("truetype"), url("../fonts/font-icon.woff") format("woff"), url("../fonts/font-icon.svg#font-icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

/* Placeholders */

/* Footer */

.footer {
  background-color: #030b17;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
}

.footer__inner {
  padding: 32px 0 0;
}

.footer__brands {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 220px;
}

.footer__brands-logo {
  max-width: 142px;
}

.footer__brands-selo {
  max-width: 76px;
}

.footer-column {
  position: relative;
  width: 100%;
  margin-top: 32px;
}

.copyright {
  margin-top: 32px;
  color: rgba(187, 187, 187, 0.7);
  letter-spacing: initial;
  text-transform: lowercase;
}

.copyright a {
  font-weight: bold;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.copyright a:hover {
  color: #fff;
}

.copyright .to-top {
  width: 37px;
  height: 53px;
  background-color: rgba(7, 25, 51, 0.5);
  border-bottom: 7px solid #ffb800;
  cursor: pointer;
  color: #fff;
  position: relative;
  font-size: 16px;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.copyright .to-top i {
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.copyright .to-top:hover {
  border-width: 12px;
}

.copyright .to-top:hover i {
  top: 11px;
}

[data-aos][data-aos][data-aos-duration="50"],
body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"],
body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate,
body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"],
body[data-aos-duration="100"] [data-aos] {
  transition-duration: 0.1s;
}

[data-aos][data-aos][data-aos-delay="100"],
body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate,
body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: 0.1s;
}

[data-aos][data-aos][data-aos-duration="150"],
body[data-aos-duration="150"] [data-aos] {
  transition-duration: 0.15s;
}

[data-aos][data-aos][data-aos-delay="150"],
body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate,
body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: 0.15s;
}

[data-aos][data-aos][data-aos-duration="200"],
body[data-aos-duration="200"] [data-aos] {
  transition-duration: 0.2s;
}

[data-aos][data-aos][data-aos-delay="200"],
body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate,
body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: 0.2s;
}

[data-aos][data-aos][data-aos-duration="250"],
body[data-aos-duration="250"] [data-aos] {
  transition-duration: 0.25s;
}

[data-aos][data-aos][data-aos-delay="250"],
body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate,
body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: 0.25s;
}

[data-aos][data-aos][data-aos-duration="300"],
body[data-aos-duration="300"] [data-aos] {
  transition-duration: 0.3s;
}

[data-aos][data-aos][data-aos-delay="300"],
body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate,
body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: 0.3s;
}

[data-aos][data-aos][data-aos-duration="350"],
body[data-aos-duration="350"] [data-aos] {
  transition-duration: 0.35s;
}

[data-aos][data-aos][data-aos-delay="350"],
body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate,
body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: 0.35s;
}

[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
  transition-duration: 0.4s;
}

[data-aos][data-aos][data-aos-delay="400"],
body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate,
body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: 0.4s;
}

[data-aos][data-aos][data-aos-duration="450"],
body[data-aos-duration="450"] [data-aos] {
  transition-duration: 0.45s;
}

[data-aos][data-aos][data-aos-delay="450"],
body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate,
body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: 0.45s;
}

[data-aos][data-aos][data-aos-duration="500"],
body[data-aos-duration="500"] [data-aos] {
  transition-duration: 0.5s;
}

[data-aos][data-aos][data-aos-delay="500"],
body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate,
body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: 0.5s;
}

[data-aos][data-aos][data-aos-duration="550"],
body[data-aos-duration="550"] [data-aos] {
  transition-duration: 0.55s;
}

[data-aos][data-aos][data-aos-delay="550"],
body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate,
body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: 0.55s;
}

[data-aos][data-aos][data-aos-duration="600"],
body[data-aos-duration="600"] [data-aos] {
  transition-duration: 0.6s;
}

[data-aos][data-aos][data-aos-delay="600"],
body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate,
body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: 0.6s;
}

[data-aos][data-aos][data-aos-duration="650"],
body[data-aos-duration="650"] [data-aos] {
  transition-duration: 0.65s;
}

[data-aos][data-aos][data-aos-delay="650"],
body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate,
body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: 0.65s;
}

[data-aos][data-aos][data-aos-duration="700"],
body[data-aos-duration="700"] [data-aos] {
  transition-duration: 0.7s;
}

[data-aos][data-aos][data-aos-delay="700"],
body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate,
body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: 0.7s;
}

[data-aos][data-aos][data-aos-duration="750"],
body[data-aos-duration="750"] [data-aos] {
  transition-duration: 0.75s;
}

[data-aos][data-aos][data-aos-delay="750"],
body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate,
body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: 0.75s;
}

[data-aos][data-aos][data-aos-duration="800"],
body[data-aos-duration="800"] [data-aos] {
  transition-duration: 0.8s;
}

[data-aos][data-aos][data-aos-delay="800"],
body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate,
body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: 0.8s;
}

[data-aos][data-aos][data-aos-duration="850"],
body[data-aos-duration="850"] [data-aos] {
  transition-duration: 0.85s;
}

[data-aos][data-aos][data-aos-delay="850"],
body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate,
body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: 0.85s;
}

[data-aos][data-aos][data-aos-duration="900"],
body[data-aos-duration="900"] [data-aos] {
  transition-duration: 0.9s;
}

[data-aos][data-aos][data-aos-delay="900"],
body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate,
body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: 0.9s;
}

[data-aos][data-aos][data-aos-duration="950"],
body[data-aos-duration="950"] [data-aos] {
  transition-duration: 0.95s;
}

[data-aos][data-aos][data-aos-delay="950"],
body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate,
body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: 0.95s;
}

[data-aos][data-aos][data-aos-duration="1000"],
body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"],
body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate,
body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"],
body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"],
body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate,
body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"],
body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"],
body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate,
body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"],
body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"],
body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate,
body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"],
body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"],
body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate,
body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"],
body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"],
body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate,
body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"],
body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"],
body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate,
body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"],
body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"],
body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate,
body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"],
body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"],
body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate,
body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"],
body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"],
body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate,
body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"],
body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"],
body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate,
body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"],
body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"],
body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate,
body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"],
body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"],
body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate,
body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"],
body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"],
body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate,
body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"],
body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"],
body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate,
body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"],
body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"],
body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate,
body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"],
body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"],
body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate,
body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"],
body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"],
body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate,
body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"],
body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"],
body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate,
body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"],
body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"],
body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate,
body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"],
body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"],
body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate,
body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"],
body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"],
body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate,
body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"],
body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"],
body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate,
body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"],
body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"],
body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate,
body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"],
body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"],
body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate,
body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"],
body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"],
body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate,
body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"],
body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"],
body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate,
body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"],
body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"],
body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate,
body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"],
body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"],
body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate,
body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"],
body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"],
body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate,
body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"],
body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"],
body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate,
body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"],
body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"],
body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate,
body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"],
body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"],
body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate,
body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"],
body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"],
body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate,
body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"],
body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"],
body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate,
body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"],
body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"],
body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate,
body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"],
body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"],
body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate,
body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"],
body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"],
body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate,
body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"],
body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"],
body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate,
body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"],
body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"],
body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate,
body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"],
body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"],
body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate,
body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos] {
  pointer-events: none;
}

[data-aos].aos-animate {
  pointer-events: auto;
}

[data-aos][data-aos][data-aos-easing=linear],
body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

[data-aos][data-aos][data-aos-easing=ease],
body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing=ease-in],
body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing=ease-out],
body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-out],
body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-back],
body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-aos][data-aos][data-aos-easing=ease-out-back],
body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back],
body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

[data-aos][data-aos][data-aos-easing=ease-in-sine],
body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-aos][data-aos][data-aos-easing=ease-out-sine],
body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine],
body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-aos][data-aos][data-aos-easing=ease-in-quad],
body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quad],
body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad],
body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic],
body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic],
body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic],
body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-quart],
body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quart],
body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart],
body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

/* Inputs */

.form-textarea,
.form-select,
.form-input {
  padding: 12px 0;
  color: #707070;
  border: 0;
  border-bottom: 1px solid #181818;
  background: transparent;
  width: 100%;
  display: block;
  border-radius: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  height: 40px;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #fff;
}

.form-textarea:disabled,
.form-select:disabled,
.form-input:disabled {
  pointer-events: none;
}

.form-textarea:focus,
.form-select:focus,
.form-input:focus {
  outline: 0;
}

.form-label {
  color: #bbbbbb;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  display: flex;
  align-items: flex-start;
}

.form-label .required {
  color: #ffb800;
}

.form-group {
  width: 100%;
  margin-bottom: 32px;
}

.form-select {
  -webkit-appearance: none;
}

.form-textarea {
  height: 144px;
  resize: none;
}

/* Cards */

.card-post {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 450px;
  position: relative;
  color: #fff;
  padding: 40px 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  border-radius: 5px;
}

.card-post::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #071933 0%, rgba(7, 25, 51, 0) 100%);
  z-index: 1;
}

.card-post > * {
  position: relative;
  z-index: 2;
}

.card-post__category {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffb800;
  margin-bottom: 20px;
}

.card-post__title {
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
}

.card-post a {
  margin-top: auto;
}

.card-post__badge {
  position: absolute;
  width: 108px;
  height: 90px;
  border-top: 54px solid transparent;
  border-right: 54px solid #ec0000;
  border-bottom: 54px solid #ec0000;
  border-left: 54px solid transparent;
  bottom: 0;
  right: 0;
  z-index: 3;
  pointer-events: none;
}

.card-post__badge i {
  font-size: 28px;
  position: absolute;
  bottom: -36px;
  right: -38px;
  color: #fff;
}

/* Message Feedback */

.message-feedback {
  position: fixed;
  bottom: 16px;
  right: -100%;
  z-index: 10000;
  background-color: #181818;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  will-change: opacity, visibility, right;
  transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.message-feedback.success {
  background-color: #030b17;
}

.message-feedback.error {
  background-color: #800015;
}

.message-feedback__content {
  width: 100%;
  max-width: 300px;
  position: relative;
  font-size: 18px;
  padding: 15px 45px 15px 15px;
  font-weight: normal;
  pointer-events: none;
}

.message-feedback__close {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;
}

.message-feedback__close i {
  transform: rotate(45deg);
}

.message-feedback.is-active {
  opacity: 1;
  right: 16px;
  visibility: visible;
}

/* Muralha */

.concept {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-position-y: bottom;
  padding: 100px 0;
}

.concept__left p + p,
.concept__right p + p {
  margin-top: 16px;
}

.concept__title {
  font-weight: 700;
  font-size: 35px;
  line-height: 34px;
  letter-spacing: -0.005em;
  position: relative;
  z-index: 1;
  margin-bottom: 16px;
}

.concept__title::before {
  content: "";
  position: absolute;
  top: -63px;
  left: -5px;
  width: 127px;
  height: 90px;
  background-size: 127px 90px;
  background-image: url("data:image/svg+xml,%3Csvg width='150' height='106' viewBox='0 0 150 106' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M85.6829 106V66.8261C85.6829 53.5121 86.3193 42.5024 87.5921 33.7971C88.865 25.0918 91.6652 18.3068 95.9929 13.442C100.575 8.32125 107.067 4.86473 115.467 3.07247C124.123 1.02416 135.578 0 149.834 0V23.0435C142.961 23.0435 137.615 23.6836 133.796 24.9638C129.978 26.244 127.432 28.6763 126.159 32.2609C124.886 35.8454 124.25 40.8382 124.25 47.2391V53.3841H147.161V106H85.6829ZM0.911621 106V66.8261C0.911621 53.5121 1.54804 42.5024 2.82088 33.7971C4.34829 25.0918 7.27583 18.3068 11.6035 13.442C15.9311 8.32125 22.4226 4.86473 31.078 3.07247C39.7333 1.02416 51.1889 0 65.4447 0V23.0435C58.5713 23.0435 53.2254 23.6836 49.4069 24.9638C45.5884 26.244 43.0427 28.6763 41.7698 32.2609C40.497 35.8454 39.8606 40.8382 39.8606 47.2391V53.3841H62.7717V106H0.911621Z' fill='%23FFB800'/%3E%3C/svg%3E");
  z-index: -1;
}

.concept__right {
  margin-top: 32px;
}

.concept__separator {
  display: block;
  margin: 0 0 20px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: 83px 16px;
  background-image: url("data:image/svg+xml,%3Csvg width='83' height='16' viewBox='0 0 83 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 16V0H18.7081V16H0Z' fill='%23FFB800'/%3E%3Cpath d='M32.146 16V0H50.854V16H32.146Z' fill='%23FFB800'/%3E%3Cpath d='M64.2919 16V0H83V16H64.2919Z' fill='%23FFB800'/%3E%3C/svg%3E");
}

.concept .city {
  position: absolute;
  max-width: 620px;
  right: 50%;
  margin-right: -290px;
  top: 100%;
}

.numbers {
  position: relative;
  margin-top: 450px;
  overflow: hidden;
}

.numbers__title {
  font-size: 35px;
  font-weight: 700;
  line-height: 101.9%;
  letter-spacing: -0.175px;
  max-width: 510px;
  color: #071933;
  margin-bottom: 16px;
}

.numbers__grid {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
}

.numbers .number {
  width: 100%;
  padding: 48px 32px;
  background: linear-gradient(180deg, rgba(112, 112, 112, 0.2) 70%, rgba(7, 25, 51, 0) 120%);
  position: relative;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 101.9%;
  letter-spacing: -0.09px;
  color: #707070;
  min-height: 172px;
}

.numbers .number::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, #fac20a 0%, rgba(250, 194, 10, 0) 100%);
}

.numbers .number small {
  font-size: 12.902px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.065px;
  display: block;
}

.numbers .number__title {
  font-weight: 700;
  font-size: 51px;
  line-height: 101.9%;
  letter-spacing: -0.255px;
  color: #071933;
  margin-bottom: 8px;
}

.numbers .data-from {
  color: #707070;
  text-align: right;
  font-size: 16px;
  font-weight: 400;
  line-height: 102%;
  letter-spacing: -0.08px;
  margin-top: 16px;
}

.cases {
  padding: 48px 0 80px;
  background-color: #ffb800;
}

.cases__title {
  color: #071933;
  font-weight: 700;
  font-size: 35px;
  line-height: 101.9%;
  letter-spacing: -0.175px;
  margin-bottom: 32px;
}

.cases__grid {
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr;
}

.cases__text {
  color: #071933;
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: -0.15px;
}

.cases__text .button {
  margin-top: 32px;
}

.quick-contact {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 60px 0;
  color: #fff;
  background-color: #030b17;
  background-blend-mode: overlay;
}

.quick-contact__title {
  font-weight: 700;
  font-size: 35px;
  line-height: 36px;
  letter-spacing: -0.005em;
}

.quick-contact__form {
  width: 100%;
  margin-top: 16px;
  background-color: #fff;
  padding: 32px 16px;
}

@media only screen and (min-width: 770px) {
  .footer__inner {
    display: flex;
    justify-content: space-between;
  }

  .footer-column {
    width: auto;
    padding-right: 52px;
    margin-left: 52px;
    margin-top: 0;
  }

  .footer-column:last-child {
    padding-right: 0;
  }

  .form-2-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    -moz-column-gap: 32px;
    column-gap: 32px;
  }

  .form-row-1-2 {
    display: grid;
    grid-template-columns: 0.7fr 0.3fr;
    grid-gap: 16px;
  }

  .concept {
    padding-bottom: 50px;
  }

  .concept__left {
    width: 50%;
    max-width: 570px;
    padding-right: 32px;
  }

  .concept__right {
    width: 50%;
    margin-left: auto;
    margin-top: 0;
  }

  .concept__separator {
    margin: 60px 0 20px;
  }

  .concept .city {
    margin-right: 50px;
    top: 30%;
  }

  .numbers {
    margin-top: 100px;
  }

  .numbers .number {
    width: 50%;
  }

  .numbers .number::before {
    top: 0;
    width: 1px;
    height: 100%;
    background: linear-gradient(180deg, #fac20a 0%, rgba(250, 194, 10, 0) 100%);
  }

  .numbers .number:nth-child(2n)::before {
    display: none;
  }

  .cases__grid {
    gap: 32px;
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 1024px) {
  .footer__inner {
    padding: 90px 0 40px;
  }

  .copyright {
    margin-top: 0;
  }

  .numbers__title {
    font-size: 40px;
    line-height: 102%;
    letter-spacing: -0.2px;
    margin-bottom: 48px;
  }

  .numbers__grid {
    margin-top: 48px;
  }

  .numbers .number {
    padding: 97px 48px;
    background: linear-gradient(180deg, rgba(172, 172, 172, 0.2) 0%, rgba(7, 25, 51, 0) 100%);
  }

  .quick-contact {
    padding: 100px 0;
  }

  .quick-contact__title {
    font-size: 40px;
    line-height: 41px;
  }

  .quick-contact__form {
    width: 54%;
    margin-left: auto;
    margin-top: 0;
    padding: 48px;
  }
}

@media only screen and (min-width: 1200px) {
  .footer__inner {
    padding: 190px 0 110px;
    display: flex;
    justify-content: space-between;
  }

  .footer__brands {
    margin-bottom: 0;
  }

  .footer__brands-logo,
  .footer__brands-selo {
    max-width: 100%;
  }

  .card-post {
    padding: 70px 30px 30px;
    height: 450px;
  }

  .concept {
    font-size: 20px;
    line-height: 28px;
    padding: 160px 0 125px;
  }

  .concept__title {
    max-width: 372px;
    margin-bottom: 32px;
    font-size: 40px;
    line-height: 40px;
  }

  .concept__title::before {
    left: -29px;
    width: 150px;
    height: 106px;
    background-size: 150px 106px;
  }

  .concept .city {
    margin-right: 100px;
    top: 430px;
    max-width: 788px;
  }

  .numbers {
    margin-top: 200px;
  }

  .numbers .number {
    min-height: 353px;
    font-size: 35px;
    line-height: 102%;
    letter-spacing: -0.175px;
  }

  .numbers .number__title {
    font-size: 100px;
    line-height: 102%;
    letter-spacing: -0.5px;
    margin-bottom: 32px;
  }

  .numbers .number small {
    font-size: 25px;
    line-height: 35px;
    letter-spacing: -0.15px;
  }

  .cases {
    padding: 145px 0;
  }

  .cases__title {
    font-size: 40px;
    line-height: 101.9%;
    letter-spacing: -0.2px;
    margin-bottom: 48px;
  }

  .cases__grid {
    gap: 9px;
    grid-template-columns: auto auto auto 390px;
  }

  .cases__text {
    padding-left: 32px;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.15px;
  }
}

@media only screen and (min-width: 1400px) {
  .quick-contact {
    padding: 210px 0;
  }

  .quick-contact__title {
    font-size: 50px;
    line-height: 51px;
  }
}

@media only screen and (min-width: 1600px) {
  .numbers {
    margin-top: 200px;
  }
}

@media only screen and (max-width: 770px) {
  .footer__brands {
    position: relative;
  }

  .footer__brands .separator {
    position: absolute;
    left: 0;
    bottom: -25px;
    width: 100%;
    height: 1px;
    background: linear-gradient(270deg, #fac20a 0%, rgba(250, 194, 10, 0) 100%);
    margin: 0;
  }
}

@media screen {
  html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
    opacity: 0;
    transition-property: opacity, transform;
  }

  html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate {
    opacity: 1;
    transform: none;
  }

  html:not(.no-js) [data-aos=fade-up] {
    transform: translate3d(0, 100px, 0);
  }

  html:not(.no-js) [data-aos=fade-down] {
    transform: translate3d(0, -100px, 0);
  }

  html:not(.no-js) [data-aos=fade-right] {
    transform: translate3d(-100px, 0, 0);
  }

  html:not(.no-js) [data-aos=fade-left] {
    transform: translate3d(100px, 0, 0);
  }

  html:not(.no-js) [data-aos=fade-up-right] {
    transform: translate3d(-100px, 100px, 0);
  }

  html:not(.no-js) [data-aos=fade-up-left] {
    transform: translate3d(100px, 100px, 0);
  }

  html:not(.no-js) [data-aos=fade-down-right] {
    transform: translate3d(-100px, -100px, 0);
  }

  html:not(.no-js) [data-aos=fade-down-left] {
    transform: translate3d(100px, -100px, 0);
  }

  html:not(.no-js) [data-aos^=zoom][data-aos^=zoom] {
    opacity: 0;
    transition-property: opacity, transform;
  }

  html:not(.no-js) [data-aos^=zoom][data-aos^=zoom].aos-animate {
    opacity: 1;
    transform: translateZ(0) scale(1);
  }

  html:not(.no-js) [data-aos=zoom-in] {
    transform: scale(0.6);
  }

  html:not(.no-js) [data-aos=zoom-in-up] {
    transform: translate3d(0, 100px, 0) scale(0.6);
  }

  html:not(.no-js) [data-aos=zoom-in-down] {
    transform: translate3d(0, -100px, 0) scale(0.6);
  }

  html:not(.no-js) [data-aos=zoom-in-right] {
    transform: translate3d(-100px, 0, 0) scale(0.6);
  }

  html:not(.no-js) [data-aos=zoom-in-left] {
    transform: translate3d(100px, 0, 0) scale(0.6);
  }

  html:not(.no-js) [data-aos=zoom-out] {
    transform: scale(1.2);
  }

  html:not(.no-js) [data-aos=zoom-out-up] {
    transform: translate3d(0, 100px, 0) scale(1.2);
  }

  html:not(.no-js) [data-aos=zoom-out-down] {
    transform: translate3d(0, -100px, 0) scale(1.2);
  }

  html:not(.no-js) [data-aos=zoom-out-right] {
    transform: translate3d(-100px, 0, 0) scale(1.2);
  }

  html:not(.no-js) [data-aos=zoom-out-left] {
    transform: translate3d(100px, 0, 0) scale(1.2);
  }

  html:not(.no-js) [data-aos^=slide][data-aos^=slide] {
    transition-property: transform;
    visibility: hidden;
  }

  html:not(.no-js) [data-aos^=slide][data-aos^=slide].aos-animate {
    visibility: visible;
    transform: translateZ(0);
  }

  html:not(.no-js) [data-aos=slide-up] {
    transform: translate3d(0, 100%, 0);
  }

  html:not(.no-js) [data-aos=slide-down] {
    transform: translate3d(0, -100%, 0);
  }

  html:not(.no-js) [data-aos=slide-right] {
    transform: translate3d(-100%, 0, 0);
  }

  html:not(.no-js) [data-aos=slide-left] {
    transform: translate3d(100%, 0, 0);
  }

  html:not(.no-js) [data-aos^=flip][data-aos^=flip] {
    backface-visibility: hidden;
    transition-property: transform;
  }

  html:not(.no-js) [data-aos=flip-left] {
    transform: perspective(2500px) rotateY(-100deg);
  }

  html:not(.no-js) [data-aos=flip-left].aos-animate {
    transform: perspective(2500px) rotateY(0);
  }

  html:not(.no-js) [data-aos=flip-right] {
    transform: perspective(2500px) rotateY(100deg);
  }

  html:not(.no-js) [data-aos=flip-right].aos-animate {
    transform: perspective(2500px) rotateY(0);
  }

  html:not(.no-js) [data-aos=flip-up] {
    transform: perspective(2500px) rotateX(-100deg);
  }

  html:not(.no-js) [data-aos=flip-up].aos-animate {
    transform: perspective(2500px) rotateX(0);
  }

  html:not(.no-js) [data-aos=flip-down] {
    transform: perspective(2500px) rotateX(100deg);
  }

  html:not(.no-js) [data-aos=flip-down].aos-animate {
    transform: perspective(2500px) rotateX(0);
  }
}