/* Message Feedback */
.message-feedback {
  position: fixed;
  bottom: $unit-spacing;
  right: -100%;
  z-index: 10000;
  background-color: $color--coal;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: $color--white;
  visibility: hidden;
  opacity: 0;
  will-change: opacity, visibility, right;
  transition: .4s $cubic;

  &.success {
    background-color: $color--blue;
  }

  &.error {
    background-color: $color--red;
  }

  &__content {
    width: 100%;
    max-width: 300px;
    position: relative;
    font-size: 18px;
    padding: 15px 45px 15px 15px;
    font-weight: normal;
    pointer-events: none;
  }

  &__close {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10;

    i {
      transform: rotate(45deg);
    }
  }

  &.is-active {
    opacity: 1;
    right: $unit-spacing;
    visibility: visible;
  }
}
