/* Footer */
.footer {
  background-color: $color--blue;
  color: $color--white;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: .045em;
  text-transform: uppercase;

  &__inner {
    padding: $unit-spacing * 2 0 0;
  }

  &__brands {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 220px;

    &-logo {
      max-width: 142px;
    }

    &-selo {
      max-width: 76px;
    }
  }

  &-column {
    position: relative;
    width: 100%;
    margin-top: $unit-spacing * 2;
  }

  @include max-screen(bp("phablet")) {
    &__brands {
      position: relative;

      .separator {
        position: absolute;
        left: 0;
        bottom: -25px;
        width: 100%;
        height: 1px;
        background: linear-gradient(270deg, #fac20a 0%, rgba(250, 194, 10, 0) 100%);
        margin: 0;
      }
    }
  }

  @include min-screen(bp("phablet")) {
    &__inner {
      display: flex;
      justify-content: space-between;
    }

    &-column {
      width: auto;
      padding-right: 52px;
      margin-left: 52px;
      margin-top: 0;

      &:last-child {
        padding-right: 0;
      }
    }
  }
  
  @include min-screen(bp("large")) {
    &__inner {
      padding: 90px 0 40px;
    }
  }

  @include min-screen(bp("page")) {
    &__inner {
      padding: 190px 0 110px;
      display: flex;
      justify-content: space-between;
    }

    &__brands {
      margin-bottom: 0;

      &-logo,
      &-selo {
        max-width: 100%;
      }
    }
  }
}

.copyright {
  margin-top: $unit-spacing * 2;
  color: rgba($color--gray5, .7);
  letter-spacing: initial;
  text-transform: lowercase;

  a {
    font-weight: bold;
    transition: .2s $cubic;

    &:hover {
      color: $color--white;
    }
  }

  .to-top {
    width: 37px;
    height: 53px;
    background-color: rgba($color--blue3, .5);
    border-bottom: 7px solid $color--yellow;
    cursor: pointer;
    color: $color--white;
    position: relative;
    font-size: 16px;
    transition: .2s $cubic;

    i {
      position: absolute;
      top: 16px;
      left: 50%;
      transform: translateX(-50%);
      transition: .2s $cubic;
    }

    &:hover {
      border-width: 12px;

      i {
        top: 11px;
      }
    }
  }

  @include min-screen(bp("large")) {
    margin-top: 0;
  }
}
