/* variables */

/* font */
$font--maven-pro: "maven pro", sans-serif;
$font--saira: "saira", sans-serif;
$font--primary: $font--maven-pro;

/* typo */
$font-size--text: 16px;

/* colors */
$color--black: #000;
$color--white: #fff;
$color--coal: #181818;

$color--blue: #030b17;
$color--blue2: #0b1e38;
$color--blue3: #071933;

$color--gray: #acacac;
$color--gray2: #707070;
$color--gray3: #6b7d99;
$color--gray4: #3a3a3c;
$color--gray5: #bbbbbb;
$color--gray6: #eeeeee;
$color--gray7: #d9d9d9;
$color--gray8: #f7f8fa;

$color--red: #800015;

$color--yellow: #ffb800;

$color--primary: $color--blue3;

$color--text: $color--black;

/* misc */
$containter-width: 1380px;
$cubic: cubic-bezier(.4, 0, .2, 1);
$unit-spacing: 16px;
$border-radius: 4px;
$box-shadow: 0 3px 5px rgba(69, 84, 91, .2), 0 0 1px rgba(69, 84, 91, .31);

$default-padding: 112px;
$default-padding-small: $unit-spacing * 2.4;

$breakpoints: (
  "minimum": 320px, //*the smallest width that the site is able to shrink to */
  "tiny": 350px,
  "small": 480px,
  "mobile": 600px,
  "phablet": 770px, //*essentially the maximum for ipads in portrait*/
  "tablet": 975px, //*!major break point!*/ /*good place to switch to tablet view*/
  "large": 1024px, //*maximum for ipads in landscape*/
  "page": 1200px, //*!major break point!*//*point at which the edge of the desktop design meets the edge of the screen*/
  "wide": 1400px,
  "big": 1600px,
  "full": 1900px
);
